import { mergeDeep } from '@graphcommerce/graphql'
import {
  AddToCartItemSelector,
  productLink,
  type ProductPageMeta,
} from '@graphcommerce/magento-product'
import { useConfigurableSelectedVariant } from '@graphcommerce/magento-product-configurable'
import { PageMeta } from '@graphcommerce/magento-store'
import { PluginProps } from '@graphcommerce/next-config'
import { useStorefrontConfig } from '@graphcommerce/next-ui'

export const component = 'ProductPageMeta'
export const exported = '@graphcommerce/magento-product'

function ErpBasedCanonicalPlugin(
  props: PluginProps<React.ComponentProps<typeof ProductPageMeta>> & AddToCartItemSelector,
) {
  const { Prev, product, index = 0, ...rest } = props

  const variantForSimpleEnabled = import.meta.graphCommerce.configurableVariantForSimple
  const variant = useConfigurableSelectedVariant({ url_key: product?.url_key, index })
  const variantProduct = variantForSimpleEnabled && variant ? mergeDeep(product, variant) : product

  const {
    sku,
    categories,
    price_range,
    media_gallery,
    name,
    meta_title,
    meta_description,
    url_key,
    __typename,
    erp_main_shop,
  } = variantProduct

  const erpMainShop = import.meta.graphCommerce.storefront.find(
    (store) => store.magentoStoreCode === erp_main_shop,
  )
  const currentDomain = useStorefrontConfig().domain
  const erpMainShopDomain = erpMainShop?.domain

  // remove when kommago.nl goes live:
  function removeNumberFromURL(url: string | null | undefined) {
    if (!url) return null
    const regex = /-\d+$/
    return url.replace(regex, '')
  }

  const kommagoUrlKey = removeNumberFromURL(url_key)

  let canonical: string

  if (currentDomain === erpMainShopDomain) {
    canonical = productLink({ url_key, __typename })
  } else if (erpMainShopDomain?.includes('kommago.nl')) {
    canonical = `https://www.kommago.nl/${kommagoUrlKey}/pid=${sku}`
  } else {
    canonical = `https://${erpMainShopDomain}/p/${url_key}`
  }
  // remove when kommago.nl goes live until here

  return (
    <Prev product={product} {...rest}>
      <PageMeta
        title={meta_title ?? name ?? ''}
        metaDescription={meta_description ?? name ?? ''}
        canonical={canonical}
        // replace when kommago.nl goes live:
        // canonical={
        //   currentDomain === erpMainShopDomain
        //     ? productLink({ url_key, __typename })
        //     : `https://${erpMainShopDomain}/p/${url_key}`
        // }
        ogImage={media_gallery?.[0]?.url}
        ogType='product'
        {...rest}
      >
        {sku && <meta property='product:retailer_part_no' content={sku} key='og-product-sku' />}
        {price_range?.minimum_price?.regular_price?.value && (
          <meta
            property='product:price:amount'
            content={price_range.minimum_price.regular_price.value.toString()}
            key='og-product-price'
          />
        )}
        {price_range?.minimum_price?.final_price?.value && (
          <meta
            property='product:sale_price:amount'
            content={price_range.minimum_price.final_price.value.toString()}
            key='og-product-sale-price'
          />
        )}
        {price_range?.minimum_price?.final_price?.currency && (
          <meta
            property='product:price:currency'
            content={price_range.minimum_price.final_price.currency}
            key='og-product-currency'
          />
        )}
        {categories &&
          categories.map((category) =>
            category?.name ? (
              <meta property='product:category' content={category.name} key={category.uid} />
            ) : null,
          )}
      </PageMeta>
    </Prev>
  )
}

export const Plugin = ErpBasedCanonicalPlugin

import { Typography, TypographyVariant } from '@mui/material'
import { ProductListItemCustomAttributesFragment } from '../ProductListItems/ProductListItemCustomAttributes.gql'

type Props = Pick<ProductListItemCustomAttributesFragment, 'product_usps'> & {
  variant?: TypographyVariant
}

export function ProductUsps(props: Props) {
  const { product_usps, variant = 'subtitle2' } = props

  if (!product_usps) return null

  return (
    <Typography
      component='div'
      variant={variant}
      sx={{ color: 'text.secondary', fontWeight: '500', pb: '3px' }}
      className='productUsps'
    >
      {product_usps
        .replace(/<\/?ul>|<\/li><li>/g, (match) => (match === '</li><li>' ? ' | ' : ''))
        .replace(/<\/?li>/g, '')}
    </Typography>
  )
}
